import React, { Fragment } from 'react';
import Navbar from './Navbar';

const Faq = () => ( 
 <Fragment> 
    <Navbar />
    <div className="container">
      <div className="row">
        <h1 className="display-4 mt-5 mb-5 ml-2">FAQ</h1>
      </div>
      <div className="accordian" id="accordianExample" >
         <div className="card border-top-0 border-left-0 border-right-0">
          <div className="card-body pl-0" id="headingOne">
            <h2 className="mb-0">
              <button className="btn pl-0 text-left" type="button" data-toggle="collapse" data-target="#collapseOne">
	        <strong>Can I try out the space to see if I like it?</strong>
              </button>
            </h2>
          </div>
          <div id="collapseOne" className="collapse show" data-parent="#accordianExample">
            <div className="card-body">
	      <p>We encourage people to check out our space by scheduling a tour Mondays-Fridays 9am-3pm.  You are invited to stay and work after your tour as a free guest for the day.</p>
            </div>
          </div>
        </div>
        <div className="card border-top-0 border-left-0 border-right-0">
          <div className="card-body pl-0" id="headingOne5">
            <h2 className="mb-0">
              <button className="btn pl-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseOne5">
	        <strong>Do you have any private offices?</strong>
              </button>
            </h2>
          </div>
          <div id="collapseOne5" className="collapse" data-parent="#accordianExample">
            <div className="card-body">
            <p>We have an open office design with 10 standard desks and 3 adjustable height desks and do not currently offer private offices.</p>
            </div>
          </div>
        </div>
        <div className="card border-top-0 border-left-0 border-right-0">
          <div className="card-body pl-0" id="headingOne8">
            <h2 className="mb-0">
              <button className="btn pl-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseOne8">
	        <strong>Can I just use the conference room to meet with a client?</strong>
              </button>
            </h2>
          </div>
          <div id="collapseOne8" className="collapse" data-parent="#accordianExample">
            <div className="card-body">
            <p>Yes, of course!  To use the conference room for a meeting, you would sign up for a daily package and reserve your meeting time on the shared calendar.  Guests who stay longer than 2 hours will be charged the daily rate.  After your meeting, you are welcome to stay and work in the space and enjoy its amenities.</p>
            </div>
          </div>
        </div>


        <div className="card border-top-0 border-left-0 border-right-0">
          <div className="card-body pl-0" id="headingTwo">
            <h2 className="mb-0">
              <button className="btn pl-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
	        <strong>Am I allowed to bring my children to Work at CoLAB?</strong>
              </button>
            </h2>
          </div>
          <div id="collapseTwo" className="collapse" data-parent="#accordianExample">
            <div className="card-body">
	     <p>We understand that life happens and sometimes childcare options are not available.  If your child is able to work or play independently and quietly so that a professional office environment can still be maintained, they are welcome to join you.  Please use your discretion and respect the needs of all your coworkers.</p>
            </div>
          </div>
        </div>
        <div className="card border-top-0 border-left-0 border-right-0">
          <div className="card-body pl-0" id="headingThree">
            <h2 className="mb-0">
              <button className="btn pl-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
	        <strong>Can I have my mail or packages delivered to Work at CoLAB? </strong>
              </button>
            </h2>
          </div>
          <div id="collapseThree" className="collapse" data-parent="#accordianExample">
            <div className="card-body">
	      <p> Full time monthly members are welcome to use the building's mailing address.  Please make sure your name is clearly indicated and pick up your mail promptly.</p>
            </div>
          </div>
        </div>
        <div className="card border-top-0 border-left-0 border-right-0">
          <div className="card-body pl-0" id="headingSix">
            <h2 className="mb-0">
              <button className="btn pl-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">
	        <strong>What is the black and white / color printing fee? </strong>
              </button>
            </h2>
          </div>
          <div id="collapseSix" className="collapse" data-parent="#accordianExample">
            <div className="card-body">
	      <p> At this time, we will not be tracking printer usage and trust that everyone will be mindful of their paper waste. [Check twice, print once!]  If your printing needs go beyond basic usage, please let us know and we will work something out. </p>
            </div>
          </div>
        </div>


        <div className="card border-top-0 border-left-0 border-right-0">
          <div className="card-body pl-0" id="headingFour">
            <h2 className="mb-0">
              <button className="btn pl-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
	        <strong>Am I only allowed to work at my desk? </strong>
              </button>
            </h2>
          </div>
          <div id="collapseFour" className="collapse" data-parent="#accordianExample">
            <div className="card-body">
	      <p>We encourage you to work in any of the common lounge areas, including the conference room.  However, if someone has reserved the conference room on the calendar, please clear the room and put everything in its original position so that it is prepared for your coworker's meeting. </p>
            </div>
          </div>
        </div>
        <div className="card border-top-0 border-left-0 border-right-0">
          <div className="card-body pl-0" id="headingFive">
            <h2 className="mb-0">
              <button className="btn pl-0 text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive">
	        <strong> Will Work at CoLAB host any community networking events? </strong>
              </button>
            </h2>
          </div>
          <div id="collapseFive" className="collapse" data-parent="#accordianExample">
            <div className="card-body">
	      <p> Absolutely!  We plan on hosting events, such as free monthly happy hours, to help community members build relationships.  We also hope to engage with the neighborhood by hosting small events in the common areas during non work day hours.  Community members are welcomed to attend these events at a discounted rate. </p>
            </div>
          </div>
        </div>
      </div>
     </div>
 </Fragment>
);

export default Faq;
