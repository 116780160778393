import React, {Fragment, useState, useEffect} from 'react';
import {Redirect } from 'react-router-dom';
import Navbar from './Navbar';
import axios from 'axios';
import {isLoggedIn} from './Auth';

const Profile = () => {
   const AuthPath = () => {
      if(isLoggedIn()) {
        return null;
      } else {
        return <Redirect to="/login" />;
      } 
    }
  const [first, setFirst]= useState(0);   

  const [formData, setFormData] = useState({
    firstname: localStorage.getItem("firstname"),
    lastname: localStorage.getItem("lastname"),
    email: '',
    linkedinURL: '',
    occupation: '',
    isPublic: '',
});

 useEffect(()=> {
   async function fetchData() {
   const curUser = {
     email : localStorage.getItem("email")
   };

  try {
    const jwt = localStorage.getItem("jwttoken"); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${jwt}`
      }
    };
    const body = JSON.stringify(curUser);
    console.log(body);
    const res = await axios.post('/api/showprofile', body, config);
    console.log(res.data);
    let pub = "";
    if(res.data.isPublic == "1") pub = "checked";
    if(res.data.firstname) { 
      setFormData({firstname: res.data.firstname,
       lastname: res.data.lastname,
       email: res.data.email,
       linkedinURL: res.data.linkedinURL,
       occupation: res.data.occupation,
       isPublic: pub});
    }
  } catch(err) {
    console.error(err);
    //localStorage.clear();
    //window.location.href="/login"; 
  }
  };
  fetchData();
  }, [first]);


const {firstname, lastname, email, linkedinURL, occupation, isPublic} = formData;

const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});
const onChangeChecked = e => setFormData({ ...formData, isPublic: !isPublic});

const onSubmit = async e => {
  e.preventDefault();
  console.log(formData);
  const editUser = {
    firstname,
    lastname,
    email,
    linkedinURL,
    occupation,
    isPublic,
    socialemail : localStorage.getItem("email")
  };
  
  try {
    const jwt = localStorage.getItem("jwttoken"); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${jwt}`
      }
    };
    const body = JSON.stringify(editUser);
    console.log(body);
    const res = await axios.post('/api/edituser', body, config);
    console.log(res.data);
    localStorage.setItem('firstname', firstname);
    localStorage.setItem('lastname', lastname);
   
    window.location.href= "/account";
  } catch(err) {
    console.error("error " + err.response.data);
    window.location.href= "/login";
  }
};


return (
  <Fragment>
    <AuthPath />
    <Navbar />
    <div className="container">
    <h1 className="display-4 mt-5 mb-5 ml-2">PROFILE</h1>
    <p className="m-2">Edit your profile</p>
    <form className="form mb-5" onSubmit={e=> onSubmit(e)}>
      <div className="form-gorup m-2">
        <input type="text" className="form-control" placeholder="First name (Required)" name="firstname" value={firstname} onChange={e=>onChange(e)} required />
      </div>
      <div className="form-gorup m-2">
        <input type="text" className="form-control" placeholder="Last name (Required)" name="lastname" value={lastname} onChange={e=>onChange(e)} required />
      </div>
      <div className="form-gorup m-2">
        <input type="text" className="form-control" placeholder="Email (Required)" name="email" value={email} onChange={e=>onChange(e)} required />
      </div>
      <div className="form-gorup m-2">
        <input type="text" className="form-control" placeholder="Linkedin Public Profile (Optional), e.g. firstname-lastname-012345" name="linkedinURL" value={linkedinURL} onChange={e=>onChange(e)} />
        <small className="text-muted form-text">
        Your linkedin profile URL is the part after https://www.linkedin.com/in/, consisting of your name and some letters and numbers (for example jane-doe-04b7a01)
        </small>
      </div>
      <div className="form-gorup m-2">
        <input type="text" className="form-control" placeholder="Occupation (Optional)" name="occupation" value={occupation} onChange={e=>onChange(e)} />
      </div>
      <div className="form-gorup m-2 ml-4 pl-1">
        <input type="checkbox" className="form-check-input" id="isPublic" name="isPublic" checked={isPublic} onChange={e=>onChangeChecked(e)} />
        <label className="form-check-label" htmlFor="isPublic">I would like my name, email, and LinkedIn visible to other members </label>
      </div>

      <input type="submit" className="btn btn-yellow m-2" value="Update Profile" />
 
    </form>
    </div>
  </Fragment>
);
};

export default Profile;
