import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Block2 extends Component {
  render() {
    return (
      <div className="block2 mb-5" id="packages">
      <div className="container">
        <div className="row">
          <h1 className="display-4 m-5">PACKAGES</h1>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-3"> 
            <div className="card border-0 text-center h-100">
              <img src="images/1x_new.png" className="card-img-top" alt="..."/>
              <div className="card-body border-bottom">
                <p className="card-text">This pay by the day rate is best for those who want to come in on a whim and cowork without any commitment.  1 hour of conference room time and all of the common amenities are included.  Guests who stay longer than 2 hours will be charged at this daily rate.*</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3"> 
            <div className="card border-0 text-center h-100">
              <img src="images/5x_new.png" className="card-img-top" alt="..."/>
              <div className="card-body border-bottom">
                <p className="card-text">Have the flexibility to cowork with minimal commitment by becoming a 5x member and getting 5 desk credits to use <b>within 3 months</b>.  This package includes 5 hours of conference room time as well as the common amenities.*</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3"> 
            <div className="card border-0 text-center h-100">
              <img src="images/10x_new.png" className="card-img-top" alt="..."/>
              <div className="card-body border-bottom">
                <p className="card-text">For those who want to cowork occasionally, this 10x membership package allows you to use a desk 10 times <b>within 3 months</b>, use the conference room for 10 hours, and access all of the common amenities.*</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3"> 
            <div className="card border-0 text-center h-100">
              <img src="images/full_new.png" className="card-img-top" alt="..."/>
              <div className="card-body border-bottom">
                <p className="card-text">The best way to guarantee a desk and take full advantage of the coworking space benefits is by becoming a monthly member.  Not only do you get a dedicated desk, unlimited conference room time, and all of the common amenities, but you also get a personal storage locker and use of the mailing address.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
	  <small className="text-muted m-3">*Desks available on a first come, first serve basis.</small>
	</div>

        <div className="row">
	  <p className="m-3"><strong>When you purchase a package, a portion of the proceeds will support <a href="http://12plus.org">12plus.org</a>, an organization dedicated to increasing educational equity in underserved Philadelphia communities.  We have had a longstanding personal relationship with 12+ since their formation and fully support their mission and values.</strong></p>
	</div>
        <div className="row">
          <div className="m-auto">
          <Link type="button" className="btn btn-yellow m-2" to="/tour">Schedule a Tour</Link>
          <Link type="button" className="btn btn-yellow m-2" to="/terms">Sign Up</Link></div>
	</div>
        <div className="row">
          <h3 className="m-5">Common Amenities</h3>
        </div>
        <div className="row">

          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/coffee_tea_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Coffee / Tea</p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/water_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Filtered Water</p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/snacks_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Snacks</p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/printer_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">BW/Color Printing</p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/lounge_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Lounge Areas</p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/conference_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Conference Room</p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/phone_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Private Phone Room </p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/online_community_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Online Community </p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/bike_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Bike Rack </p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/parking_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Parking Lot </p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/wifi_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">High Speed Wifi </p>
              </div>
            </div>
	  </div> 
          <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-4"> 
            <div className="card border h-100">
              <img src="images/supplies_yellow.png" className="card-img-top w-50 m-auto pt-4" alt="..."/>
              <div className="card-body text-center">
                <p className="card-text">Office Supplies </p>
              </div>
            </div>
	  </div> 



        </div>

      </div>
      </div>
    );
  }
}

export default Block2;
