import React, {Component,Fragment} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import axios from 'axios';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = { pkg: null};
  }

  async submit(ev) {
     // User clicked submit
    ev.preventDefault();

    if(this.state.pkg == null) {
     alert("You must select a package");
    }
    else{
    let {token} = await this.props.stripe.createToken({name:"workatcolab"});
    if(token) {
      let chargetype = '';
      if(this.state.pkg == 1) {
        chargetype='WorkatCoLAB_3mo';
      }
      if(this.state.pkg == 2) {
        chargetype='WorkatCoLAB_daily';
      }
      if(this.state.pkg == 3) {
        chargetype='WorkatCoLAB_5x';
      }
      if(this.state.pkg == 4) {
        chargetype='WorkatCoLAB_10x';
      }
      if(this.state.pkg == 5) {
        chargetype='WorkatCoLAB_full';
      }

      const payUser = {
        firstname : localStorage.getItem("firstname"),
        lastname : localStorage.getItem("lastname"),
        email : localStorage.getItem("email"),
        socialemail : localStorage.getItem("email"),
        chargetype: chargetype,
        stripetoken : token
      };
     try {
      const jwt = localStorage.getItem("jwttoken"); 
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${jwt}`
        }
      };
      const body = JSON.stringify(payUser);
      console.log(body);
      const res = await axios.post('/api/pay', body, config);
      console.log(res.data);
      window.location.href= "/checkoutpending";
    } catch(err) {
      console.error("error " + err.response.data);
      //window.location.href= "/login";
      alert("Error.. something went wrong "+err.response.data);
    }
   }

     //alert(this.state.pkg);
    }
  }
  selectPkg(numpkg) {
    this.setState({pkg: numpkg});
  }

  render() {
  const PackageItem = () => {
    //if(this.state.pkg == 1) {
    //  return <p><strong> You have selected the FULL=$350 Package.  You will have a guaranteed desk for the entire month. </strong></p>;
    //}
    //else if(this.state.pkg == 1){

    if(this.state.pkg == 2){
      return <p><strong> You have selected the 1X = $40 Package.  This is the daily rate.  This is activated immediately when purchased.</strong></p>;
    }
    if(this.state.pkg == 3){
      return <p><strong> You have selected the 5X = $175 Package.  You have 5 visits to use within the next 3 months starting today.</strong></p>;
    }
    if(this.state.pkg == 4){
      return <p><strong> You have selected the 10X = $250 Package.  You have 10 visits to use within the next 3 months starting today.</strong></p>;
    }
    if(this.state.pkg == 5){
      return <p><strong> You have selected the FULL = $400 Package.  You will have a guaranteed desk for the next month!</strong></p>;
    }

    if(this.state.pkg == 1){
      return <p><strong> You have selected the OPENING = $700 Package.  You will have a guaranteed desk for the next 3 months. This special package includes a 33% discount!</strong></p>;
    }
    return null; 
  }

    return (
      <Fragment>
      <div className="row">
        <div className="col-12">
          <p>Select your package: </p>
          <button type="button" className="btn btn-yellow m-2" onClick={()=>this.selectPkg(2)}>1X = $40</button>
          <button type="button" className="btn btn-yellow m-2" onClick={()=>this.selectPkg(3)}>5X = $175</button>
          <button type="button" className="btn btn-yellow m-2" onClick={()=>this.selectPkg(4)}>10X = $250</button>
          <button type="button" className="btn btn-yellow m-2" onClick={()=>this.selectPkg(5)}>FULL = $400</button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-4 mb-5">
          <PackageItem />
          <p>Would you like to complete the purchase?</p>
          <CardElement />
          <button type="button" className="btn btn-yellow mt-4" onClick={this.submit}>Pay</button>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default injectStripe(CheckoutForm);
