import React, { Component, Fragment } from 'react';
import Navbar from './Navbar';
import axios from 'axios';

class CheckoutPending extends Component { 
  state = { 
    pending: 'Pending...',
    icon: 'loading.gif'
  };

  SwitchIcon() {
    this.setState({icon:'check.svg',
    pending:'Success!'
    });
    setTimeout(()=>{window.location.href="/account";}, 1000);
  }

  componentDidMount() {
    setTimeout(this.CheckSuccess.bind(this), 2000);
    setTimeout(this.Wrong.bind(this), 12000);
  }

  Wrong() {
    this.setState({
    pending:'Something went wrong...'
    });
    setTimeout(()=>{window.location.href="/account";}, 10000);
  }



  async CheckSuccess() {
  try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          }
        };
        const cUser = {
          email : localStorage.getItem('email') 
        };
        const body = JSON.stringify(cUser);
        console.log(body);
        const res = await axios.post('/api/checkPay', body, config);
        console.log(res);
        if(res.data.paid == true) {
          this.SwitchIcon().bind(this);
        }
        else {
          setTimeout(this.CheckSuccess.bind(this), 1000);
        }
        
      } catch(err) {
        //console.error(err.response.data);
      }


  };

  render() {
    const Pending = () => {
      return <h4 className="mt-5"> {this.state.pending} </h4>;
    }
    const Loading = () => {
      return <img className="w-25" src={this.state.icon} />;
    }

    return (
      <Fragment>
      <Navbar />
      <div className="container">
        <div className="row text-center">
         <div className="offset-1 col-10">
          <Pending />
          <Loading />
         </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default CheckoutPending;
