import React, { Component, Fragment } from 'react';
import Navbar from './Navbar';


class Gallery extends Component {


  componentDidMount() {
var modalId = $('#image-gallery');

    function loadGallery(setIDs, setClickAttr) {
      let current_image,
        selector,
        counter = 0;

      $('#show-next-image, #show-previous-image')
        .click(function () {
          if ($(this)
            .attr('id') === 'show-previous-image') {
            current_image--;
          } else {
            current_image++;
          }

          selector = $('[data-image-id="' + current_image + '"]');
          updateGallery(selector);
        });

      function updateGallery(selector) {
        let $sel = selector;
        current_image = $sel.data('image-id');
        $('#image-gallery-title')
          .text($sel.data('title'));
        $('#image-gallery-image')
          .attr('src', $sel.data('image'));
        disableButtons(counter, $sel.data('image-id'));
      }

      if (setIDs == true) {
        $('[data-image-id]')
          .each(function () {
            counter++;
            $(this)
              .attr('data-image-id', counter);
          });
      }
      $(setClickAttr)
        .on('click', function () {
          updateGallery($(this));
        });
   }


    loadGallery(true, 'a.thumbnail');

    //This function disables buttons when needed
    function disableButtons(counter_max, counter_current) {
      $('#show-previous-image, #show-next-image')
        .show();
      if (counter_max === counter_current) {
        $('#show-next-image')
          .hide();
      } else if (counter_current === 1) {
        $('#show-previous-image')
          .hide();
      }
    }
  }

 render() {
    /**
     *
     * @param setIDs        Sets IDs when DOM is loaded. If using a PHP counter, set to false.
     * @param setClickAttr  Sets the attribute for the click handler.
     */

// build key actions

 return (
 <Fragment> 
    <Navbar />
    <div className="container">
      <div className="row">
        <h1 className="display-4 mt-5 mb-5 ml-2">GALLERY</h1>
      </div>
	<div className="row">
       	  <div className="row">
            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Open work space" //data-title="Work Space Private Desks" 
                   //data-image="images/24_openspace.png"
                   data-image="images/04_open_work_space_facing_conference_room.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         //src="images/24_openspace.png"
                         src="images/04_open_work_space_facing_conference_room.png"
                         alt="Open work space without projection screen" />
                </a>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Open work space" //data-title="Work Space Private Desks" 
                   data-image="images/01_open_work_space_without_projection_screen.png"
                   //data-image="images/01a_open_work_space_and_entrance.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/01_open_work_space_without_projection_screen.png"
                         //src="images/01a_open_work_space_and_entrance.png"
                         alt="Open work space and entrance" />
                </a>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Open work space with projector equipment" //data-title="Work Space Private Desks" 
                   data-image="images/03_open_work_space_with_projector_capabilities01.png"
                   //data-image="images/20_openspace.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/03_open_work_space_with_projector_capabilities01.png"
                         //src="images/20_openspace.png"
                         alt="Open work space" />
                </a>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Adjustable height desks"
                   data-image="images/26_desk.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/26_desk.png"
                         alt="Ajustable height desk" />
                </a>
            </div>


            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Kitchen Area"
                   data-image="images/21_kitchen.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/21_kitchen.png"
                         alt="Open work space facing conference" />
                </a>
            </div>
          <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Reusable Mugs"
                   data-image="images/13_mug_hooks.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/13_mug_hooks.png"
                         alt="Mug hooks" />
                </a>
            </div>


           <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Front entrance"
                   data-image="images/08_front_entrance.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/08_front_entrance.png"
                         alt="Front entrance" />
                </a>
            </div>


            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Conference room lounge area"
                   data-image="images/07_conference_room_lounge_area.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/07_conference_room_lounge_area.png"
                         alt="Conference room lounge area" />
                </a>
            </div>
            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Beverage station"
                   data-image="images/28_snacks.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/28_snacks.png"
                         alt="Beverage station" />
                </a>
            </div>
 

            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Private phone room"
                   data-image="images/27_phone.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/27_phone.png"
                         alt="Private phone room" />
                </a>
            </div>

            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Entry"
                   data-image="images/09_entry.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/09_entry.png"
                         alt="Entry" />
                </a>
            </div>
               <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Conference room"
                   data-image="images/06_conference_room.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/06_conference_room.png"
                         alt="Conference room" />
                </a>
            </div>
 
 
            <div className="offset-lg-3 col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Printer Area"
                   data-image="images/29_printers.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/29_printers.png"
                         alt="Printer Area" />
                </a>
            </div>

 
            <div className="col-lg-3 col-md-4 col-xs-6 thumb">
                <a className="thumbnail" href="#" data-image-id="" data-toggle="modal" data-title="Available Monitors"
                   data-image="images/30_monitors.png"
                   data-target="#image-gallery">
                    <img className="img-thumbnail"
                         src="images/30_monitors.png"
                         alt="Available Monitors" />
                </a>
            </div>



        </div>


        <div className="modal fade" id="image-gallery" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="image-gallery-title"></h4>
                        <button type="button" className="close" data-dismiss="modal"><span aria-hidden="true">×</span><span className="sr-only">Close</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <img id="image-gallery-image" className="img-responsive col-md-12" src="" />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary float-left" id="show-previous-image"><i className="fa fa-arrow-left"></i>
                        </button>

                        <button type="button" id="show-next-image" className="btn btn-secondary float-right"><i className="fa fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </div>
     </div>
 </Fragment>
);

}
}
export default Gallery;
