import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Navbar from './Navbar';
import OnePage from './OnePage';
import Faq from './Faq';
import Coming from './Coming';
import Footer from './Footer';
import Checkout from './Checkout';
import CheckoutPending from './CheckoutPending';
import Login from './Login';
import Logout from './Logout';
import Account from './Account';
import Profile from './Profile';
import Community from './Community';
import Contact from './Contact';
import Terms from './Terms';
import Tour from './Tour';
import Gallery from './Gallery';
import Covid from './Covid';
import GetPlans from './GetPlans';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
	<Switch>
        <Route exact path='/' component={OnePage} />
        <Route exact path='/landing' component={OnePage} />
        <Route exact path='/faq' component={Faq} />
        <Route exact path='/checkout' component={Checkout} />
        <Route exact path='/checkoutpending' component={CheckoutPending} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/profile' component={Profile}/>
        <Route exact path='/account' component={Account} />
        <Route exact path='/community' component={Community} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/terms' component={Terms} />
        <Route exact path='/tour' component={Tour} />
        <Route exact path='/gallery' component={Gallery} />
        <Route exact path='/covid' component={Covid} />
        <Route exact path='/get-plans' component={GetPlans} />
	</Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
