import React, { Component, Fragment } from 'react';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import Navbar from './Navbar';
import axios from 'axios';

class Login extends Component { 
  render() {
    const responseGoogle = async (response) => {
      console.log(response);
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          }
        };
        const gUser = {
          firstname : response.profileObj.givenName,
          lastname : response.profileObj.familyName,
          email : response.profileObj.email,
          tokenId : response.tokenId
        };
        const body = JSON.stringify(gUser);
        console.log(body);
        const res = await axios.post('/google/redirect', body, config);
        console.log(res);
        localStorage.setItem('jwttoken', res.data.token);
        localStorage.setItem('firstname', gUser.firstname);
        localStorage.setItem('lastname', gUser.lastname);
        localStorage.setItem('email', gUser.email);
        if(res.data.firsttime == true) {
          this.props.history.push('/profile');
        }
        else {
          this.props.history.push('/account');
        }
        
      } catch(err) {
        console.error(err.response.data);
      }

    }

    const responseGoogleFail = (response) => {
      console.log(response);
    }

    const responseFacebook = async (response) => {
      console.log(response);
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
          }
        };
        const fUser = {
          firstname : ((response.name).split(" "))[0],
          lastname : ((response.name).split(" "))[1],
          email : response.email,
          tokenId : response.accessToken
        };
        const body = JSON.stringify(fUser);
        console.log(body);
        const res = await axios.post('/facebook/redirect', body, config);
        console.log(res);
        localStorage.setItem('jwttoken', res.data.token);
        localStorage.setItem('firstname', fUser.firstname);
        localStorage.setItem('lastname', fUser.lastname);
        localStorage.setItem('email', fUser.email);
        if(res.data.firsttime == true) {
          this.props.history.push('/profile');
        }
        else {
          this.props.history.push('/account');
        }

      } catch(err) {
        console.error(err.data);
      }

    }


    return (
      <Fragment>
      <Navbar />
      <div className="container">
        <div className="row">
         <div className="offset-2 col-8 offset-lg-4 col-lg-4 text-center">
          <img className="w-75" src='images/colab_blue.png'/>
         </div>
        </div>
        <div className="row text-center">
         <div className="col-12">
          <p> Log in to Work at CoLAB </p>
          <a href="http://localhost:5000/google/redirect">
          <GoogleLogin
            clientId="182850317416-93pkl4hbql1pbksgk0hg1d765av10g11.apps.googleusercontent.com"
            buttonText="Login With Google"
            className="loginBtn btnGoogle"
            onSuccess={responseGoogle}
            onFailure={responseGoogleFail}
          />
          </a>
         </div>
        </div>
        <div className="row text-center">
         <div className="col-12">
          <FacebookLogin
            appId="355900128634475"
            fields="name,email,picture"
            cssClass="loginBtn btnFacebook"
            callback={responseFacebook}
          />
         </div>
        </div>

        <div className="row">
         <div className="offset-2 col-8 offset-lg-4 col-lg-4 text-center my-5">
           <small>For security purposes, we require you to login using Google or Facebook.  <b>We never request or store passwords.</b> </small>
           <small>We are requesting the minimum amount of personal information necessary to authenticate you.</small>
         </div>
        </div>
      </div>
      </Fragment>
    );
  }
}

export default Login;
