import React, { Component, Fragment } from 'react';
import Navbar from './Navbar';
import axios from 'axios';

class Logout extends Component { 
  render() {
    localStorage.clear();
    this.props.history.replace('/');

    return (
      <Navbar />
    );
  }
}

export default Logout;
