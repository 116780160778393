import React, { Fragment } from 'react';
import Navbar from './Navbar';

const Tour = () => ( 
 <Fragment> 
    <Navbar />
    <div className="container">
      <div className="row">
        <h1 className="display-4 mt-5 mb-5 ml-2">Schedule a Tour</h1>
      </div>
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdXGFunFJ-AJpvi9ucC-uLlk5GBX_2XKNBdDT_AZwhQ0ZW-pQ/viewform?embedded=true" width="640" height="1379" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
     </div>
 </Fragment>
);

export default Tour;
