import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <div className="footer">
      <div className="container">
        <div className="row">
          <div className="offset-md-3 col-md-6 col-12">
          <p className="p-2 text-center">
         <br/><br/><small>Work at CoLAB <br/> 895 Glenbrook Ave  Bryn Mawr, PA 19010 </small>
          </p>
          </div>

          <div className="col-md-3 col-12 pt-3">
          <p className="pt-5 text-right">
            <a className="btn-icon-yellow m-2" target="_blank" href="https://facebook.com/workatcolab"><i className="fa fa-facebook-official fa-2x"></i></a>
            <a className="btn-icon-yellow m-2" target="_blank" href="https://www.instagram.com/workatcolab/"><i className="fa fa-instagram fa-2x"></i></a>
            <a className="btn-icon-yellow m-2" target="_blank" href="https://www.linkedin.com/company/work-at-colab/about/"><i className="fa fa-linkedin fa-2x"></i></a>
          </p>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default Footer;
