import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import {isLoggedIn} from './Auth';
import {Redirect} from 'react-router';
import axios from 'axios';

class Account extends Component { 
  state = {
    enddate : '',
    enddatenoformat : '',
    startdate: '',
    plannum: '',
    planremain: '',
    plantype: '',
    checkedin: '',
    numworkers: '',
    subscriptions: [],
    stripeactive: '',
    stripeend: '',
  };
  

   async componentDidMount() {
  }

  async getPlan() {
 let options = {weekday: 'long', year:'numeric', month:'long', day:'numeric'};
  const curUser = {
    socialemail : localStorage.getItem("email")
  };

  try {
    const jwt = localStorage.getItem("jwttoken"); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${jwt}`
      }
    };
    const body = JSON.stringify(curUser);
    //console.log(body);
    const res = await axios.post('/api/showplan', body, config);
    //console.log(res.data);
    if(res.data.valid == true) { 
      const edate = new Date(res.data.enddate).toLocaleDateString("en-US", options);
      let plan = "";
      let plantot = "";
      let planrem = "";
      let todaycheckin = "";
      let numin = "";
      if(res.data.plantype == "WorkatCoLAB_full"){
        plan = "You are subscribed to the Monthly plan with FULL access!";
        plantot = "Unlimited";
        planrem = "Unlimited";
      }
      if(res.data.plantype == "WorkatCoLAB_5x"){
        plan = "You are subscribed to the 5x/month plan";
        plantot = "5";
        planrem = "5";
      }
      if(res.data.plantype == "WorkatCoLAB_10x"){
        plan = "You are subscribed to the 10x/month plan";
        plantot = "10";
        planrem = "10";
      }
      if(res.data.plantype == "WorkatCoLAB_daily"){
        plan = "You are subscribed to the daily plan";
        plantot = "1";
        planrem = "0";
      }
      const curUser2 = {
        socialemail : localStorage.getItem("email"),
        startdate : res.data.startdate,
        enddate : res.data.enddate
      };

      const body2 = JSON.stringify(curUser2);
      const res2 = await axios.post('/api/getcheckin', body2, config);
      if(res2.data.length > 0) {
        planrem = parseInt(planrem) - parseInt(res2.data.length);
        planrem = planrem.toString();
        let today = new Date();
        let lastcheckin = new Date(res2.data[res2.data.length-1].time);
        if(today.getDate() == lastcheckin.getDate()) {
          todaycheckin = "1";
        } 
      }
      const res3 = await axios.post('/api/checkNumUsers', body, config);
      numin = 4;
      if(res3.data.length > 0) {
        numin = 4 - parseInt(res3.data[res3.data.length-1].counter);
      }
      numin = numin.toString();

      console.log(res3.data);
      this.setState({enddate: edate,
       enddatenoformat : res.data.enddate,
       plannum: plantot,
       planremain: planrem,
       startdate: res.data.startdate,
       checkedin: todaycheckin,
       numworkers: numin,
       plantype: plan});
    }
    else {
      this.setState({enddate: "No Active Plan.",
       plannum: "0",
       planremain: "0",
       numworkers: "4",
       plantype: "No Plan Exists"});
    }
    try {
	  const curUser = {
    		socialemail : localStorage.getItem("email")
  	  };
    	const jwt = localStorage.getItem("jwttoken"); 
    	const config = {
      	headers: {
        	'Content-Type': 'application/json',
        	'Authorization': `JWT ${jwt}`
      		}
    	};
    	const body = JSON.stringify(curUser);
    	//console.log(body);
    	const response = await axios.post('/active-subscriptions', body, config);
	console.log(response);
 
      const subscriptions = response;
      this.setState({ subscriptions});
	function formatTimestamp(timestamp) {
	    const date = new Date(timestamp * 1000); // Convert to milliseconds
	    const year = date.getFullYear();
	    const month = date.toLocaleString('default', { month: 'long' });
	    const day = date.getDate();

	    return `${month} ${day}, ${year}`;
	}
       if (subscriptions && subscriptions.data && subscriptions.data.data && subscriptions.data.data.length > 0) {
	    this.setState({stripeactive:"1",
		stripeend: formatTimestamp(subscriptions.data.data[0].current_period_end)});
	      this.setState({enddate: formatTimestamp(subscriptions.data.data[0].current_period_end) + " and is set to autorenew through stripe",
       		plannum: "Unlimited",
       		planremain: "Unlimited",
       		plantype: "You are subscribed to the Montly plan with FULL access!"});
        } else {
	    this.setState({stripeactive:"0",});
        }
    } catch (error) {
      console.error(error);
    }

  } catch(err) {
    console.error("error " + err.response.data);
    localStorage.clear();
    window.location.href="/login"; 
  }
  }

  componentWillMount() {
    this.getPlan();
  }

  render() {
   const AuthPath = () => {
      if(isLoggedIn()) {
        return null;
      } else {
        return <Redirect to="/login" />;
      } 
    }
    const Welcome = () => {
      const firstname = localStorage.getItem("firstname");
      const lastname = localStorage.getItem("lastname");
      return <h3 className="m-2">Welcome, {firstname} {lastname}! </h3>;
    }
const onSubmit = async e => {
  e.preventDefault();
  const curUser = {
    socialemail : localStorage.getItem("email")
  };
  try {
    const jwt = localStorage.getItem("jwttoken"); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${jwt}`
      }
    };
    const body = JSON.stringify(curUser);
    const res = await axios.post('/api/checkinuser', body, config);
    //console.log(res.data);
    
    window.location.reload();
  } catch(err) {
    console.error("error " + err.response.data);
    window.location.href= "/login";
  }
};

const Tdate = () => {
 let options = {weekday: 'long', year:'numeric', month:'long', day:'numeric'};
 return new Date().toLocaleDateString("en-US", options);
}

const ShowCredits = () => {
  return <p className="m-2">You have <strong>{this.state.planremain} out of {this.state.plannum}</strong> credits available until <strong>{this.state.enddate}</strong></p>;
}
const ShowPlan = () => {
  return <p className="m-2">{this.state.plantype}</p>;
}
const ShowNumUsers = () => {
  return <p className="m-2"> There are <strong> {this.state.numworkers} out of 4 </strong> floating desks available today.</p>;
}


const ShowCheckin = () => {
  let button = "";
  if(this.state.planremain > 0) {
     button = <input type="submit" className="btn btn-yellow m-2" value="Check In" />;
     if(this.state.checkedin == "1") {
       button = <p className="btn btn-yellow btn-large m-2 disabled"> You have checked in</p>;
     }
  }
  return button;
}
const ShowDate = () => {
  return <p className="m-2">Your plan is active until: <strong>{this.state.enddate}</strong></p>;
}

    return (
      <Fragment>
      <AuthPath />
      <Navbar />
      <div className="container">
        <h1 className="display-4 mt-5 mb-5 ml-2">ACCOUNT</h1>
        <Welcome />
        
        <p className="m-2">Today is <Tdate />.</p>
        <ShowNumUsers />
        <ShowCredits />
      <form className="form" onSubmit={e=> onSubmit(e)}>
        <ShowCheckin />
      </form>
      <hr />
        <h3 className="m-2">Purchase, upgrade, or renew your plan</h3>
        <ShowPlan />
        <ShowDate />
          <Link type="button" className="btn btn-yellow m-2" to="/terms">Purchase Plan</Link>
      <hr />
        <h3 className="m-2">Event and Conference Room Calendar</h3>
<iframe src="https://calendar.google.com/calendar/b/1/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FNew_York&amp;src=d29ya2F0Y29sYWJAZ21haWwuY29t&amp;src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%23039BE5&amp;color=%2333B679&amp;color=%230B8043" className="w-100" height="600" frameborder="0" scrolling="no"></iframe>
      <hr />
      </div>
      </Fragment>
    );
  }
}

export default Account;
