import React, { Component, Fragment } from 'react';
import Navbar from './Navbar';
import {isLoggedIn} from './Auth';
import {Redirect} from 'react-router';
import axios from 'axios';



class Community extends Component { 
   state = {
     people: []
   } 
   
   async getPeople() {
  try {
    const jwt = localStorage.getItem("jwttoken"); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${jwt}`
      }
    };
    const res = await axios.post('/api/getpeople', null, config);
    console.log(res.data);
    if(res.data) { 
      this.setState({people:res.data});
    }
  } catch(err) {
    console.error(err);
    //localStorage.clear();
    //window.location.href="/login"; 
  }
}
  componentWillMount() {
    this.getPeople();
  }


 render() {
   const AuthPath = () => {
      if(isLoggedIn()) {
        return null;
      } else {
        return <Redirect to="/login" />;
      } 
    }
 
 return (
 <Fragment> 
    <AuthPath />
    <Navbar />
    <div className="container">
        <h1 className="display-4 mt-5 mb-5 ml-2">COMMUNITY</h1>

       {this.state.people.map((item,key) => {
        return <p>{item.firstname} {item.lastname} - {item.occupation}, {item.email}</p>;
      })}
    </div>
 </Fragment>
 );}
}

export default Community;
