import React, { Component } from 'react';

class Block1 extends Component {
    
  render() {
    var exampleCallback = function() {
        console.log('Order complete!');
    };

    window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: '84438514871',
        modal: true,
        modalTriggerElementId: 'eventbrite-widget-modal-trigger-84438514871',
        onOrderComplete: exampleCallback
    });

    return (
      <div className="block1">
      <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
     <div className="carousel-inner">
       <div className="carousel-item active">
         <img className="d-block col-12" src="/images/openspace1.png" alt="First slide"/>
       </div>
       <div className="carousel-item">
         <img className="d-block col-12" src="/images/kitchen2.png" alt="Second slide"/>
       </div>
       <div className="carousel-item">
         <img className="d-block col-12" src="/images/mugs1.png" alt="Third slide"/>
       </div>
     </div>
     <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
      <span className="sr-only">Previous</span>
     </a>
     <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true"></span>
      <span className="sr-only">Next</span>
     </a>
   </div>
   </div>
    );
  }
}

export default Block1;
