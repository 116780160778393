import React, { Fragment } from 'react';
import Navbar from './Navbar';

const Covid = () => ( 
 <Fragment> 
    <Navbar />
    <div className="container">
      <div className="row">
        <h1 className="display-4 mt-5 mb-5 ml-2">COVID-19</h1>
      </div>
      <div className="row ml-2">
	<h4> We will be implementing the following COVID-19 related policies.</h4>
	<ul>
	  <li className="ml-2 mt-3">All members and their guests must be fully vaccinated before utilizing the space.  Proof of vaccination is required.</li>
	  <li className="ml-2 mt-3">The available number of desks in the main space will be reduced from 13 to 6.  These desks will be separated to maintain 6'-0" of distance between coworkers, with three height adjustable desks facing the windows and three desks facing the kitchen space.  </li>
	  <li className="ml-2 mt-3">The daily pass option will no longer available until further notice to reduce the amount of traffic through the space.</li>
	  <li className="ml-2 mt-3">There will be a count of the number of people who have checked in for the day when you log into your account so that you can gauge how crowded the space will be and see whether they feel comfortable coming in.  In order for this to be accurate, please remember to check in each time you arrive.  </li>
	  <li className="ml-2 mt-3">We encourage meetings to remain online as much as possible.  If it is absolutely necessary to use the conference room for an in-person meeting with external guests, we ask that their names be listed in the shared google calendar for contact tracing purposes.  Any guest that stay longer than 2 hours will be charged the daily rate.  We also ask non-members to enter through the front door and wear face masks in all common areas.  Please wipe down all hard surfaces in the shared conference room with disinfectant before and after each use.</li>
	  <li className="ml-2 mt-3">There will be more frequent cleanings of the space and a Medical Grade H13 True HEPA filter air purifier run throughout the day.  There will also be disinfectants, paper towels, gloves, and other cleaning supplies made readily available for your use.  </li>
	  <li className="ml-2 mt-3"> Verbiage has been added to the Work at CoLAB Membership Terms and Conditions that states each member and any guests of members should be clear of all COVID-19 symptoms listed on the CDC website before entering the building.  If anyone exhibits COVID-19 symptoms or knows they have been in contact with a COVID-19 infected individual, they are NOT permitted to work in the space until they are cleared by a doctor and must notify us immediately so that we can perform contact tracing.  </li>
	</ul>
      </div>

     </div>
     <br/>
 </Fragment>
);

export default Covid;
