import React, { Fragment } from 'react';

const Coming = () => ( 
 <Fragment> 
   <div className="container">
     <img src="images/comingsoon.jpg" className="w-50 m-auto"/>
   </div>
 </Fragment>
);

export default Coming;
