import React, { Fragment } from 'react';
import Block1 from './Block1';
import Block2 from './Block2';
import Block3 from './Block3';
import Navbar from './Navbar';

const OnePage = () => ( 
 <Fragment> 
   <Navbar />
   <Block1 />
   <Block2 />
   <Block3 />
 </Fragment>
);

export default OnePage;
