import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import {isLoggedIn} from './Auth';

class Navbar extends Component {
  render() {
    const LogLi = () => {
      if(isLoggedIn()) {
        return <li className="nav-item px-2"> <Link className="nav-link" to="/logout">LOG OUT</Link> </li>;
      } else {
        return <li className="nav-item px-2"> <Link className="nav-link" to="/login">LOG IN</Link> </li>;
      } 
    }
    const CommunityLi = () => {
      if(isLoggedIn()) {
        return <li className="nav-item px-2"> <Link className="nav-link" to="/community">COMMUNITY</Link> </li>;
      } else {
        return null;
      } 
    }

    const ProfileLi = () => {
      if(isLoggedIn()) {
        return <li className="nav-item px-2"> <Link className="nav-link" to="/profile">PROFILE</Link> </li>;
      } else {
        return null;
      } 
    }
    const AccountLi = () => {
      if(isLoggedIn()) {
        return <li className="nav-item px-2"> <Link className="nav-link" to="/account">ACCOUNT</Link> </li>;
      } else {
        return null;
      } 
    }


    return (
      <nav className="navbar navbar-expand-lg navbar-dark pl-4 pb-0 pt-1 pr-2">
        <a className="navbar-brand p-0" href="/"><img className="logosize" src="images/logoreduced.png"/></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse order-1 dual-collapse2" id="navbarNav">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item px-3">
              <Link className="nav-link" to="/#packages">PACKAGES</Link>
            </li>
            <li className="nav-item px-3">
              <a className="nav-link" href="/#vision">VISION</a>
            </li>
            <li className="nav-item px-3">
              <Link className="nav-link" to="/faq">FAQ</Link>
            </li>
            <li className="nav-item px-3">
              <Link className="nav-link" to="/gallery">GALLERY</Link>
            </li>


            </ul>
        </div>
        <div className="collapse navbar-collapse order-2 dual-collapse2" id="navbarNav">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item px-2">
              <Link className="nav-link" to="/contact">CONTACT</Link>
            </li>
            <AccountLi />
            <ProfileLi />
            <LogLi />
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
