import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import {isLoggedIn} from './Auth';
import {Redirect} from 'react-router';
import axios from 'axios';

class GetPlans extends Component { 
  state = {
    yourData: [],
  };
  
  async getPlan() {

  try {
    const jwt = localStorage.getItem("jwttoken"); 
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${jwt}`
      }
    };
    const res = await axios.get('/get-plans', config);
    console.log(res.data);
    if(res.data) { 
      console.log(res.data);
      this.setState({yourData: res.data,
       });
    }
    else {
      this.setState({enddate: "No Active Plan.",
       plantype: "No Plan Exists"});
    }
	console.log(this.state);

  } catch(err) {
	if(err.response) {
    		console.error("error " + err.response.data);
  	} else {
    	console.error(err);
  	}
    localStorage.clear();
    window.location.href="/login"; 
  }
  }

  componentWillMount() {
    this.getPlan();
  }

  render() {
   const AuthPath = () => {
      if(isLoggedIn()) {
        return null;
      } else {
        return <Redirect to="/login" />;
      } 
    }

    return (
      <Fragment>
      <AuthPath />
      <Navbar />
      <div className="container">
        <h1 className="display-4 mt-5 mb-5 ml-2">ACCOUNTS</h1>
	<ul>
        {this.state.yourData.map((item, index) => (
	  <li key={index}>{item.firstname} {item.lastname} - Checkins: {item.checkin_count}, Start: {item.startdate}, End: {item.enddate}, {item.plantype} </li>
        ))}
        </ul>
      <hr />
      </div>
      </Fragment>
    );
  }
}

export default GetPlans;
