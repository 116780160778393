import React, { Component } from 'react';

class Block3 extends Component {
  render() {
    return (
      <div className="block3" id="vision">
      <div className="container">
        <div className="row">
          <h1 className="display-4 m-5">VISION</h1>
        </div>
        <div className="row">
          <p className="m-1 ml-5 mr-5 mb-3">
Work at CoLAB is a space where people with a variety of career interests and backgrounds come together to work, collaborate, and create. Fostering new relationships and seeing those turn into exciting new ventures—that’s what drives us. We encourage mutual respect, honesty, and a willingness to help others. Work at CoLAB is a community of dedicated professionals—each taking pride in their role, benefiting each other and the surrounding neighborhood.

          </p>
          <p className="m-1 ml-5 mr-5 mb-3">
At Work at CoLAB, our mission of community building also extends to the world around us. We know that we have a responsibility to be a respectful and helpful steward of this planet, and we take that responsibility seriously. We strive to be an eco-friendly small business by reducing single use products and waste, providing dishware and utensils, serving naturally biodegradable pod coffee, and dispensing filtered water. We also offer bike racks to encourage coworkers who live nearby to reduce their carbon footprint. 

	  </p>
          <p className="m-1 ml-5 mr-5 mb-5">
Join our community and see your business thrive. We’re excited to welcome you to Work at CoLAB!
          </p>
        </div>
      </div>
      </div>
    );
  }
}

export default Block3;
