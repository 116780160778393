import React, { Component, Fragment } from 'react';
import Navbar from './Navbar';
import {Redirect} from 'react-router';



class Contact extends Component { 
 render() {
 return (
 <Fragment> 
    <Navbar />
    <div className="container">
      <h1 className="display-4 mt-5 mb-5 ml-2">CONTACT</h1>
      <div className="row ml-2">
        <p className="ml-2"><strong>email:</strong> <a href="mailto:contact@workatcolab.com"> contact@workatcolab.com</a></p>
      </div>
      <div className="row ml-2">
        <p className="ml-2"><strong>telephone:</strong> 484.380.5813</p>
      </div>
      <div className="row ml-2">
        <p className="ml-2"><strong>address:</strong> 895 Glenbrook Ave, Bryn Mawr, PA 19010</p>
      </div>
      <div className="row ml-2">
        <p className="ml-2"><strong>hours:</strong> Monday - Friday, 9:00am to 6:00pm <br/>Limited space and limited staff through the rest of 2020.</p>
      </div>

      <div className="row ml-2">
     <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3055.5042234210086!2d-75.32630208388093!3d40.019520479413316!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c009f0b92001%3A0x854eea9b53f42552!2s895%20Glenbrook%20Ave%2C%20Bryn%20Mawr%2C%20PA%2019010!5e0!3m2!1sen!2sus!4v1568322218367!5m2!1sen!2sus" className="w-100" height="500px" frameBorder="0" allowFullScreen="{true}"></iframe> 
      </div>
    </div>
 </Fragment>
 );}
}

export default Contact;
